import { InputAdornment} from '@mui/material';
import style from './areas.module.css'
import { Page } from "components/page/page.component";
import { TextInput } from 'components/textInput/textInput.component';
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Icon } from 'components/icon/icon.component';
import { Button } from 'components/button/button.component';
import { Skeleton } from 'components/skeleton';
import { Table, TableHead, TableBody, TableCell, TableRow  } from 'components/table/table.component'
import { useEffect, useState } from 'react';
import { removeAccentsAndSpecialChars } from 'utils/stringManage.util';
import { FEATURE } from 'data/features.data';
import { Filters } from 'components/filters/filters.component';
import { FilterType } from 'model/filter.model';
import { useGetAreasApi } from 'api/area.api';


type AreasPageProps = {
    companyId: string
}

export function AreasPage({companyId}: AreasPageProps){

    const [search, setSearch] = useState('')

    const [building, setBuilding] = useState<string>('')
    const {data: _areas, loading, get: getAreasApi} = useGetAreasApi(building ?? '')

    useEffect(()=>{
        getAreas()
    // eslint-disable-next-line
    }, [building])

    function getAreas(){
        getAreasApi().catch(e => console.log(e.message))
    }

    const areas = search ? _areas?.filter(_ => removeAccentsAndSpecialChars(_.name.toLowerCase()).includes(removeAccentsAndSpecialChars(search.toLowerCase())) ) : _areas

    function handleSearchChange(text: string){
        setSearch(text)
    }

    function handleFiltersChange(filter: FilterType){
        setBuilding(filter.buildings?.at(0)?.id ?? '')
    }
    
    return <Page className={style.Page} menu header title="Andares">
    <View className={style.Content}>
        <View className={style.FiltersContainer} features={[FEATURE.BUILDING_CHOOSE_COMPANY.id]}>
            <Filters showBuildings onlyOneBuilding companyId={companyId} onChange={handleFiltersChange} />
        </View>
        <View className={style.ContentHeader}>
            
            <TextInput 
                fullWidth 
                InputProps={{
                    startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
                  }}
                value={search}
                onChange={(e)=> handleSearchChange(e.target.value)}
                size='small'
            />
            <a href={`${companyId ? `/${companyId}` : ''}/andar`}>
                <Button className={style.AddButton} variant='contained' size='medium' ><Icon primaryContrast >add_circle</Icon>Novo Andar</Button>
            </a>
        </View>
    {loading
    ?  <Skeleton className={style.Skeleton} />
    : <View className={style.TableContainer}>
        <View className={style.TableContent}>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell><Text type='h4'>Nome</Text></TableCell>
                        <TableCell><Text type='h4'>Ativo</Text></TableCell>
                        <TableCell><Text type='h4'>Ações</Text></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    !building
                    ?<TableRow className={style.Content}>
                        <TableCell colSpan={5}>
                            <Text type='sm'>Selecione um prédio</Text>
                        </TableCell>
                    </TableRow>
                    : (!areas || areas?.length <= 0)
                    ? <TableRow className={style.Content}>
                        <TableCell colSpan={5}>
                            <Text type='sm'>Nenhum Andar encontrado</Text>
                        </TableCell>
                    </TableRow>
                    : areas.map(area => <TableRow key={area.id}>
                        <TableCell><Text type='sm'>{area.name}</Text></TableCell>
                        <TableCell><Text type='sm'>{!area.disable ? 'Ativo' : 'Inativo'}</Text></TableCell>
                        <TableCell>
                            <View title='Editar andar'>
                            <a href={`/${companyId}/andar/${area.id}`}>
                                <Icon >edit_square</Icon>
                            </a>
                            </View>
                        </TableCell>
                    </TableRow>)
                    }
                </TableBody>
            </Table>
        </View>
    </View>
    }
    </View>
</Page>
}