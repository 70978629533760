import style from './graphic.module.css'
import { useGetIndicatorRecordsApi } from "api/indicatorRecord.api";
import { Button } from 'components/button/button.component';
import { Graphic } from "components/graphic/graphic.component";
import { Page } from "components/page/page.component";
import { Skeleton } from 'components/skeleton';
import { Text } from "components/text/text.component";
import { View } from "components/view/view.component";
import { useEffect, useState } from "react";
import { getOneYearBefore, sortByDate } from 'utils/date.util';
import { IndicatorRecordsTable } from 'components/indicatorRecordsTable/indicatorRecordsTable.component';
import { Filters } from 'components/filters/filters.component';
import { arraysEqual } from 'utils/stringManage.util';
import { FilterType } from 'model/filter.model';
import { useGetIndicatorApi } from 'api/indicator.api';
import { Building } from 'model/bulding.model';

type GraphicPageProps = {
    companyId?: string,
    indicatorId?: string
}

export function GraphicPage({companyId, indicatorId}: GraphicPageProps){
    const [startDate, setStartDate] = useState(getOneYearBefore(new Date()))
    const [endDate, setEndDate] = useState(new Date())
    const [buildingsSelected, setBuildingsSelected] = useState<Building[]>([])
    const [groupBy, setGroupBy] = useState(false)
    const {loading: indicatorRecordsLoading, get: getIndicatorRecordsApi, data: indicatorRecords } = useGetIndicatorRecordsApi()
    const {get: getIndicator, data: indicator} = useGetIndicatorApi(indicatorId ?? '')
    
    const indicatorRecordsSorted = indicatorRecords?.sort((a,b) => {
        const nameComparison = a.building.name?.localeCompare(b.building.name ?? '')
                            
        if(nameComparison && nameComparison !== 0) return nameComparison
        
        return sortByDate(new Date(a.date), new Date(b.date), true)
    })

    useEffect(()=>{
        if(indicatorId) getIndicator({}).catch(()=>{})
    // eslint-disable-next-line
    },[indicatorId])

    useEffect(()=>{
        if(!buildingsSelected || buildingsSelected.length <= 0) return
        getIndicatorRecords()
    // eslint-disable-next-line
    },[startDate, endDate, buildingsSelected])
    
    function getIndicatorRecords(){
        if(!companyId) return
        if(!indicatorId) return
        getIndicatorRecordsApi({
            buildings: buildingsSelected.map(_ => _.id) ?? [],
            indicators: [indicatorId],
            companyId: companyId,
            startDate: startDate,
            endDate: endDate
        }).catch(()=>{})
    }

    function handleFilterChange(filter: FilterType){
        if(filter.startDate && filter.startDate !== startDate) setStartDate(filter.startDate)
        if(filter.endDate && filter.endDate !== endDate) setEndDate(filter.endDate)
        if(filter.buildings && !arraysEqual(filter.buildings.map(_ => _.id), buildingsSelected.map(_ => _.id))) setBuildingsSelected(filter.buildings)
    }

    return <Page menu header title={`Gráfico${indicator ? ` - ${indicator.name}` : ''}`}>
         <View className={style.Content}>
            
            <Filters 
                companyId={companyId} 
                showStartDate 
                showEndDate 
                showBuildingGroups 
                showBuildings 
                showAreas={indicator?.locationAnchor === 'area' || indicator?.locationAnchor === 'room'} 
                showRooms={indicator?.locationAnchor === 'room'}
                onChange={handleFilterChange} />
            
            {indicatorRecordsLoading 
            ? <Skeleton className={style.GraphicContainer} color='bg3' />
            :<View className={style.GraphicContainer}>
                {!buildingsSelected || buildingsSelected.length <= 0
                ? <Text type='h3'>Selecione pelo menos um prédio</Text>
                : !indicatorRecordsSorted || indicatorRecordsSorted.length <= 0
                ? <Text type='h3'>Nenhum dado encontrado</Text>
                :<View className={style.GraphicContent}>
                    <View className={style.ContentHeaderSection} style={{justifyContent: 'flex-end', alignItems: 'center'}}>
                        <View className={style.ContentHeaderSectionItem} style={{alignItems: 'flex-end'}}>
                            <Button onClick={()=> setGroupBy(!groupBy)}>{groupBy? 'Agrupar por mês' : 'Agrupar por prédio' }</Button>
                        </View>
                    </View>
                    <View className={style.GraphicArea}>
                        <Graphic type='bar' showLegends indicators={indicatorRecordsSorted ?? []} groupBy={groupBy ? 'building': 'month'} />
                    </View>
                </View>}
            </View>
            }

            {indicatorRecordsLoading
            ? <Skeleton className={style.GraphicContent} color='bg3' />
            :
            (!buildingsSelected || buildingsSelected.length <= 0) || (!indicatorRecordsSorted || indicatorRecordsSorted.length <= 0)
            ? <></>
            :<View className={style.TableContent}>
                <Text type='h3'>Observações</Text>
                <IndicatorRecordsTable indicatorRecords={indicatorRecordsSorted} showBuilding showDate showValue showDescription />
            </View>}

        </View>
    </Page>
}