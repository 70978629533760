import { host } from "config/api.config";
import { Area } from "model/area.model";
import { useHTTPClient } from "services/httpClient.service";

const url = `${host}/areas`

export function useGetAreasApi(buildingId: string){

    const {data, loading, error, get} = useHTTPClient<Area[]>(`${url}?buildingId=${buildingId}`)

    function getApi(){
        return get({})
    }

    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useGetAreaApi(areaId: string){

    const {data, loading, error, get} = useHTTPClient<Area>(`${url}/${areaId}`)

    function getApi(){
        return get({})
    }

    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useSaveNewAreaApi(){
    const {loading, error, post } = useHTTPClient<Area>(`${url}`)

    async function save(data: Omit<Area, 'id'>){
        return await post({data: data})
    }

    return {
        loading,
        error,
        save
    }
}

export function useUpdateAreaApi(id: string){
    const {loading, error, put } = useHTTPClient<Partial<Area>>(`${url}/${id}`)

    async function save(data: Partial<Area>){
        return await put({data: data})
    }

    return {
        loading,
        error,
        save
    }
}