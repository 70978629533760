import { InputAdornment} from '@mui/material';
import style from './rooms.module.css'
import { Page } from "components/page/page.component";
import { TextInput } from 'components/textInput/textInput.component';
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Icon } from 'components/icon/icon.component';
import { Button } from 'components/button/button.component';
import { Skeleton } from 'components/skeleton';
import { Table, TableHead, TableBody, TableCell, TableRow  } from 'components/table/table.component'
import { useEffect, useState } from 'react';
import { removeAccentsAndSpecialChars } from 'utils/stringManage.util';
import { FEATURE } from 'data/features.data';
import { Filters } from 'components/filters/filters.component';
import { FilterType } from 'model/filter.model';
import { useGetRoomsApi } from 'api/room.api';


type RoomsPageProps = {
    companyId: string
}

export function RoomsPage({companyId}: RoomsPageProps){

    const [search, setSearch] = useState('')

    const [building, setBuilding] = useState<string>('')
    const [area, setArea] = useState<string>('')
    const {data: _rooms, loading, get: getRoomsApi} = useGetRoomsApi(companyId, building ?? '', area ?? '')

    useEffect(()=>{
        getRooms()
    // eslint-disable-next-line
    }, [building, area])

    function getRooms(){
        getRoomsApi().catch(e => console.log(e.message))
    }

    const rooms = search ? _rooms?.filter(_ => removeAccentsAndSpecialChars(_.name.toLowerCase()).includes(removeAccentsAndSpecialChars(search.toLowerCase())) ) : _rooms

    function handleSearchChange(text: string){
        setSearch(text)
    }

    function handleFiltersChange(filter: FilterType){
        setBuilding(filter.buildings?.at(0)?.id ?? '')
        setArea(filter.areas?.at(0)?.id ?? '')
    }
    
    return <Page className={style.Page} menu header title="Salas">
    <View className={style.Content}>
        <View className={style.FiltersContainer} features={[FEATURE.BUILDING_CHOOSE_COMPANY.id]}>
            <Filters showBuildings onlyOneBuilding showAreas onlyOneArea companyId={companyId} onChange={handleFiltersChange} />
        </View>
        <View className={style.ContentHeader}>
            
            <TextInput 
                fullWidth 
                InputProps={{
                    startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment>,
                  }}
                value={search}
                onChange={(e)=> handleSearchChange(e.target.value)}
                size='small'
            />
            <a href={`${companyId ? `/${companyId}` : ''}/sala`}>
                <Button className={style.AddButton} variant='contained' size='medium' ><Icon primaryContrast >add_circle</Icon>Novo Sala</Button>
            </a>
        </View>
    {loading
    ?  <Skeleton className={style.Skeleton} />
    : <View className={style.TableContainer}>
        <View className={style.TableContent}>
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell><Text type='h4'>Nome</Text></TableCell>
                        <TableCell><Text type='h4'>Ativo</Text></TableCell>
                        <TableCell><Text type='h4'>Ações</Text></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                    // !building
                    // ?<TableRow className={style.Content}>
                    //     <TableCell colSpan={5}>
                    //         <Text type='sm'>Selecione um prédio</Text>
                    //     </TableCell>
                    // </TableRow>
                    // : !area ? 
                    // <TableRow className={style.Content}>
                    //     <TableCell colSpan={5}>
                    //         <Text type='sm'>Selecione um andar</Text>
                    //     </TableCell>
                    // </TableRow>
                    // : 
                    (!rooms || rooms?.length <= 0)
                    ? <TableRow className={style.Content}>
                        <TableCell colSpan={5}>
                            <Text type='sm'>{`Nenhuma sala encontrado`}</Text>
                        </TableCell>
                    </TableRow>
                    : rooms.map(room => <TableRow key={room.id}>
                        <TableCell><Text type='sm'>{room.name}</Text></TableCell>
                        <TableCell><Text type='sm'>{!room.disable ? 'Ativo' : 'Inativo'}</Text></TableCell>
                        <TableCell>
                            <View title='Editar sala'>
                            <a href={`/${companyId}/sala/${room.id}`}>
                                <Icon >edit_square</Icon>
                            </a>
                            </View>
                        </TableCell>
                    </TableRow>)
                    }
                </TableBody>
            </Table>
        </View>
    </View>
    }
    </View>
</Page>
}