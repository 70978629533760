import { Page } from "components/page/page.component";
import { RoomReservationCard } from "components/roomReservationCard/roomReservationCard.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { useEffect } from "react";
import style from './dashboard.module.css'
import { useGetRoomsApi } from "api/room.api";
import { PieCard } from "components/pieCard/pieCard.component";
import { useGetRoomReservationDashboardDataApi } from "api/dashboard.api";
import { differenceInMinutes } from "utils/date.util";

interface DashboardPageProps {
    companyId?: string
}

export function DashboardPage({companyId}: DashboardPageProps){

    const {get: getRooms, data: rooms, loading: loadingRooms} = useGetRoomsApi(companyId ?? '', '', '')

    useEffect(()=>{
        getRooms()
    // eslint-disable-next-line
    }, [])

    return <Page menu header title="Dashboard">
        <View className={style.PageContent}>
            {/* <Text>{data ? new Date(data[0].date).toLocaleString() : '----'}</Text> */}
        {
            loadingRooms
            ?
            <View className={style.Indicators}>
                <PieCard loading/> 
                <PieCard loading/> 
                <PieCard loading/> 
                <PieCard loading/>
                <PieCard loading/>
                <PieCard loading/>
            </View>
            :
            !rooms || rooms.length <= 0 
            ?<Text>nenhuma sala encontrada</Text>
            :<View className={style.Indicators}>
                {rooms.map(_ => <RoomReservationItem roomId={_.id} roomName={_.name} />)}
                {/* <RoomReservationCard color={getColor(data ? data[0].reserved : false, data ? data[0].occupied : false)} title='Sede - Mezanino - TS.PM1.R02' reserved={data ? data[0].reserved : false} occupied={data ? data[0].occupied : false}/>
                <RoomReservationCard color={getColor(true, false)} reserved />
                <RoomReservationCard color='yellow' reserved />
                <RoomReservationCard color={getColor(false, true)} occupied />
                <RoomReservationCard color={getColor(true, true)} reserved occupied /> */}
            </View>
        }
        </View>
    </Page>
}

type RoomReservationItemProps = {
    roomId: string,
    roomName: string
}

function RoomReservationItem({roomId, roomName}: RoomReservationItemProps){
    const {get, data} = useGetRoomReservationDashboardDataApi(roomId)

    console.log(data)
    
    useEffect(()=>{

        function getData() {
            get()
        }

        getData()

        const interval = setInterval(getData, 60000)

        return () => clearInterval(interval)
    // eslint-disable-next-line
    },[])

    function getColor(reserved: boolean, occupied: boolean, startDate?: Date){
        if(reserved && occupied) return 'red'
        if(reserved && startDate && (differenceInMinutes(new Date(), startDate)) <= 15) return 'yellow'
        if(reserved) return 'blue'
        if(occupied) return 'orange'
        return 'green'
    }

    return <RoomReservationCard 
        color={getColor(data ? data.reserved : false, data ? data.occupied : false, data?.reserveStartAt)} 
        title={roomName}
        reserved={data ? data.reserved : false} 
        occupied={data ? data.occupied : false}
        reservedBy={data?.reservedBy}
        reserveStartAt={data?.reserveStartAt}
        reserveEndAt={data?.reserveEndAt}
    />
}