import { ProtectedRoute } from 'components/protectedRoute/protectedRoute.component';
import { FEATURE } from "data/features.data";
import { BuildingPage } from "pages/building/building.page";
import { BuildingGroupPage } from "pages/buildingGroup/buildingGroup.page";
import { BuildingGroupsPage } from "pages/buildingGroups/buildingGroups.page";
import { BuildingsPage } from "pages/buildings/buildings.page";
import { ChooseCompanyPage } from "pages/chooseCompany/chooseCompany.page";
import { CompaniesPage } from "pages/companies";
import { CompanyPage } from "pages/company/company.page";
import { DashboardPage } from "pages/dashboard/dashboard.page";
import { ForbiddenPage } from "pages/forbidden/forbidden.page";
import { ForgotPasswordPage } from "pages/forgotPassword";
import { GraphicPage } from "pages/graphic/graphic.page";
import { IndicatorPage } from "pages/indicator/indicator.page";
import { IndicatorsPage } from "pages/indicators/indicators.page";
import { NotFoundPage } from "pages/notFound";
import { PendingRecordsPage } from 'pages/pendingRecords/pendingRecords.page';
import { ReservationReportPage } from 'pages/reports/reservationReport/reservationReport.page';
import { ReportsPage } from "pages/reports/singleIndicatorReport/reports.page";
import { SigninPage } from "pages/signin";
import { UpdatePasswordPage } from "pages/updatePassword";
import { UserPage } from "pages/user/user.page";
import { UsersPage } from "pages/users/users.page";
import { useParams } from "react-router-dom";
import { Navigate } from "services/navigation.service";
import { GraphicPage as SantanderGraphicPage } from 'pages/graphic/graphic.santander.page';
import { DashboardPage as SantanderDashboardPage } from 'pages/dashboard/dashboard.santander.page';
import { AreasPage } from 'pages/areas/areas.page';
import { RoomsPage } from 'pages/rooms/rooms.page';
import { AreaPage } from 'pages/area/area.page';
import { RoomPage } from 'pages/room/room.page';

export const routes = [
    {
        path: '/login',
        element: <SigninPage />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/signin',
        element: <Navigate to="/login" replace />,
        errorElement: <NotFoundPage />
    },
    {
        //arotech.app/signin/brookfield
        path: '/signin/:companySlug',
        element: <Navigate to="/login" replace />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/redefinir-senha',
        element: <UpdatePasswordPage />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/nao-autorizado',
        element: <ProtectedRoute><ForbiddenPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/escolha-empresa',
        element: <ProtectedRoute features={[FEATURE.BUILDING_CHOOSE_COMPANY.id]}><ChooseCompanyPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/',
        element: <HomeElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/:companyId',
        element: <HomeElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/pendencia-lancamentos',
        element: <PendingRecordsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/pendencia-lancamentos',
        element: <PendingRecordsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/dashboard',
        element: <DashboardElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/dashboard',
        element: <DashboardElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/empresas',
        element: <ProtectedRoute features={[FEATURE.COMPANY_LIST.id]}><CompaniesPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/empresas',
        element: <ProtectedRoute features={[FEATURE.COMPANY_LIST.id]}><CompaniesPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/empresa/:companyId',
        element: <CompanyElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/empresa',
        element: <ProtectedRoute features={[FEATURE.COMPANY_CREATE.id, FEATURE.COMPANY_READ.id]}><CompanyPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/predios',
        element: <ProtectedRoute features={[FEATURE.BUILDING_LIST.id]}><BuildingsPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/predios',
        element: <ProtectedRoute features={[FEATURE.BUILDING_LIST.id]}><BuildingsPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/predio/:buildingId',
        element: <BuildingElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'predio',
        element: <ProtectedRoute features={[FEATURE.BUILDING_CREATE.id, FEATURE.BUILDING_READ.id]}><BuildingPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/grupo-de-predios/:buildingGroupId',
        element: <BuildingGroupElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/grupo-de-predios',
        element: <BuildingGroupElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'grupos-de-predios/:buildingGroupId',
        element: <BuildingGroupElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/grupos-de-predios',
        element: <BuildingGroupsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'grupos-de-predios',
        element: <BuildingGroupsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'usuarios',
        element: <UsersElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/usuarios',
        element: <UsersElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'usuario',
        element: <ProtectedRoute><UserPage /></ProtectedRoute>,
        errorElement: <NotFoundPage />
    },
    {
        path: 'usuario/:userId',
        element: <UserElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/usuario/:userId',
        element: <UserElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/lancamento-indicadores/:indicatorId',
        element: <IndicatorsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'lancamento-indicadores/:indicatorId',
        element: <IndicatorsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/lancamento-indicador/:indicatorId',
        element: <IndicatorElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/lancamento-indicador/:indicatorId/:indicatorRecordId',
        element: <IndicatorElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/relatorio',
        element: <ReportsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'relatorio',
        element: <ReportsElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/relatorio/reserva-sala',
        element: <ReservationReportElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'relatorio/reserva-sala',
        element: <ReservationReportElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/grafico/:indicatorId',
        element: <GraphicElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'grafico/:indicatorId',
        element: <GraphicElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/grafico/reserva-sala',
        element: <SantanderGraphicElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: 'grafico/reserva-sala',
        element: <SantanderGraphicElement />,
        errorElement: <NotFoundPage />
    },
    {
        path: '/andares',
        element: <RouteElement _protected needCompany Component={AreasPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/andares',
        element: <RouteElement _protected needCompany Component={AreasPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/salas',
        element: <RouteElement _protected needCompany Component={RoomsPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/salas',
        element: <RouteElement _protected needCompany Component={RoomsPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/andar',
        element: <RouteElement _protected needCompany Component={AreaPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/andar',
        element: <RouteElement _protected needCompany Component={AreaPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/andar/:id',
        element: <RouteElement _protected needCompany Component={AreaPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/andar/:id',
        element: <RouteElement _protected needCompany Component={AreaPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/sala',
        element: <RouteElement _protected needCompany Component={RoomPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/sala',
        element: <RouteElement _protected needCompany Component={RoomPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: '/sala/:id',
        element: <RouteElement _protected needCompany Component={RoomPage}/>,
        errorElement: <NotFoundPage />
    },
    {
        path: ':companyId/sala/:id',
        element: <RouteElement _protected needCompany Component={RoomPage}/>,
        errorElement: <NotFoundPage />
    }
]

function HomeElement(){
    const {companyId} = useParams()
    // return <ProtectedRoute company={companyId} needCompany ><PendingRecordsPage companyId={companyId ?? ''}/></ProtectedRoute>
    return <ProtectedRoute 
    company={companyId} 
    needCompany
    >
        <Navigate to="dashboard" replace />
    </ProtectedRoute>
}

function PendingRecordsElement(){
    const {companyId} = useParams()
    return <ProtectedRoute company={companyId} needCompany features={[FEATURE.PENDING_RECORDS_LIST.id]} redirectToWhenNotAllowed={<Navigate to="/dashboard" replace />} ><PendingRecordsPage companyId={companyId ?? ''}/></ProtectedRoute>
}

function DashboardElement(){
    const {companyId} = useParams()
    if(companyId === 'santander-demo') return <ProtectedRoute company={companyId} needCompany ><SantanderDashboardPage companyId={companyId} /></ProtectedRoute>
    return <ProtectedRoute company={companyId} needCompany ><DashboardPage companyId={companyId} /></ProtectedRoute>
}

function CompanyElement(){
    const {companyId} = useParams()
    return <ProtectedRoute company={companyId} needCompany features={[FEATURE.COMPANY_CREATE.id, FEATURE.COMPANY_UPDATE.id, FEATURE.COMPANY_DELETE.id, FEATURE.COMPANY_READ.id]} needAllFeatures ><CompanyPage companyId={companyId}/></ProtectedRoute>
}

function BuildingElement(){
    const {companyId, buildingId} = useParams()
    return <ProtectedRoute company={companyId} features={[FEATURE.BUILDING_UPDATE.id, FEATURE.BUILDING_READ.id]}><BuildingPage id={buildingId} companyId={companyId}/></ProtectedRoute>
}

function BuildingGroupElement(){
    const {companyId, buildingGroupId} = useParams()
    return <ProtectedRoute company={companyId}><BuildingGroupPage companyUsername={companyId} buildingGroupId={buildingGroupId} /></ProtectedRoute>
}

function BuildingGroupsElement(){
    const {companyId} = useParams()
    return <ProtectedRoute company={companyId} needCompany features={[FEATURE.BUILDING_GROUP_LIST.id]}><BuildingGroupsPage companyUsername={companyId}/></ProtectedRoute>
}

function UsersElement(){
    const {companyId} = useParams()
    return <ProtectedRoute company={companyId} needCompany  features={[FEATURE.USER_LIST.id]}><UsersPage companyId={companyId} /></ProtectedRoute>
}

function UserElement(){
    const {userId} = useParams()
    return <ProtectedRoute features={[FEATURE.USER_CREATE.id, FEATURE.USER_READ.id, FEATURE.USER_UPDATE.id]}><UserPage userId={userId}/></ProtectedRoute>
}

function IndicatorsElement(){
    const {companyId, indicatorId} = useParams()
    return <ProtectedRoute company={companyId} needCompany features={[FEATURE.INDICATOR_RECORD_LIST.id]}><IndicatorsPage companyId={companyId ?? ''} indicatorId={indicatorId ?? ''} /></ProtectedRoute>
}

function IndicatorElement(){
    const {companyId, indicatorId, indicatorRecordId} = useParams()
    return <ProtectedRoute company={companyId} needCompany features={[FEATURE.INDICATOR_RECORD_READ.id ,FEATURE.INDICATOR_RECORD_CREATE.id, FEATURE.INDICATOR_RECORD_UPDATE.id]}><IndicatorPage companyId={companyId ?? ''} indicatorId={indicatorId ?? ''} indicatorRecordId={indicatorRecordId} /></ProtectedRoute>
}

function ReportsElement(){
    const {companyId} = useParams()
    if(companyId === 'santander-demo') return <Navigate to="reserva-sala" replace />
    return <ProtectedRoute company={companyId} needCompany features={[]}><ReportsPage companyId={companyId} /></ProtectedRoute>
}

function ReservationReportElement(){
    const {companyId} = useParams()
    return <ProtectedRoute company={companyId} needCompany features={[]}><ReservationReportPage companyId={companyId} /></ProtectedRoute>
}

function GraphicElement(){
    const {companyId, indicatorId} = useParams()
    if(companyId === 'santander-demo') return <Navigate to="reserva-sala" replace />
    return <ProtectedRoute company={companyId} needCompany features={[]}><GraphicPage companyId={companyId} indicatorId={indicatorId} /></ProtectedRoute>
}

function SantanderGraphicElement(){
    const {companyId} = useParams()
    return <ProtectedRoute company={companyId} needCompany features={[]}><SantanderGraphicPage companyId={companyId} /></ProtectedRoute>
}

type RouteElementProps = {
    _protected?: boolean,
    needCompany?: boolean
    features?: string[],
    needAllFeatures?: boolean,
    Component: React.ElementType
}

function RouteElement({_protected, needCompany, features, needAllFeatures, Component}: RouteElementProps){
    const urlParams = useParams()
    if(_protected){
        return <ProtectedRoute company={urlParams.companyId} needCompany={needCompany} features={features} needAllFeatures={needAllFeatures}><Component {...urlParams} /> </ProtectedRoute>
    }else{
        return <Component {...urlParams} />
    }
}