import { View } from 'components/view/view.component'
import { Text } from 'components/text/text.component'
import style from './filters.component.module.css'
import { Skeleton } from 'components/skeleton'
import { useEffect, useState } from 'react'
import { getMonthsBefore, getOneYearBefore } from 'utils/date.util'
import { Select, SelectItem } from 'components/select/select.component'
import { DatePicker } from 'components/datePicker/datePicker.component'
import { useGetCompanyIndicatorsApi } from 'api/company.api'
import { useGetBuildingGroupsApi } from 'api/buildingGroup.api'
import { useGetBuildingsApi } from 'api/building.api'
import { arraysEqual } from 'utils/stringManage.util'
import { FilterType } from 'model/filter.model'
import { Checkbox } from 'components/checkbox/checkbox.component'
import { useGetRoomsApi } from 'api/room.api'
import { useGetAreasApi } from 'api/area.api'

interface FiltersProps {
    companyId?: string
    showBuildings?: boolean
    onlyOneBuilding?: boolean
    showAreas?: boolean
    onlyOneArea?: boolean
    showRooms?: boolean
    onlyOneRoom?: boolean
    showIndicators?: boolean
    showDashboardIndicators?: boolean
    showBuildingGroups?: boolean
    showStartDate?: boolean
    showEndDate?: boolean
    showDate?: boolean
    dateType?: 'month' | 'datetime',
    initialStartDate?: Date,
    initialEndDate?: Date,
    indicatorsFor?: 'input' | 'dashboard' | 'graphic' | 'report'
    onChange?: (filter: FilterType) => void
}

export function Filters({companyId, showBuildings, onlyOneBuilding, showAreas, onlyOneArea, showRooms, onlyOneRoom, showIndicators, showDashboardIndicators, showBuildingGroups, showStartDate, showEndDate, showDate, dateType = 'month', initialStartDate, initialEndDate, indicatorsFor, onChange }: FiltersProps) {

    const _initialStartDate = initialStartDate ?? getOneYearBefore(getMonthsBefore( new Date(), 2))
    const _initialEndDate = initialEndDate ?? getMonthsBefore(new Date(), 2)

    const [buildingsSelected, setBuildingsSelected] = useState<string[]>([])
    const [areasSelected, setAreasSelected] = useState<string[]>([])
    const [roomsSelected, setRoomsSelected] = useState<string[]>([])
    const [indicatorsSelected, setIndicatorsSelected] = useState<string[]>([])
    const [dashboardIndicatorsSelected, setDashboardIndicatorsSelected] = useState<number[]>([])
    const [buildingGroupSelected, setBuildingGroupSelected] = useState<string>('')
    const [startDate, setStartDate] = useState<Date | undefined>(_initialStartDate)
    const [endDate, setEndDate] = useState<Date | undefined>(_initialEndDate)
    const [date, setDate] = useState<Date | undefined>(_initialEndDate)


    const {loading: loadingIndicators, get: getIndicators, data: indicators} = useGetCompanyIndicatorsApi(companyId ?? '') 
    const {loading: loadingBuildingGroups, get: getBuildingGroups, data: buildingGroups} = useGetBuildingGroupsApi(companyId ?? '')
    const {loading: buildingsLoading, get: getBuildings, data: buildings} = useGetBuildingsApi(companyId ?? '')
    const {loading: areasLoading, get: getAreas, data: areas} = useGetAreasApi(buildingsSelected[0] ?? '')
    const {loading: roomsLoading, get: getRooms, data: rooms} = useGetRoomsApi(companyId ?? '', buildingsSelected[0] ?? '', areasSelected[0] ?? '')

    const _indicators = _getIndicators()
    const _dashboardIndicators = indicators?.dashboardIndicators ?? []

    function _getIndicators(){
        if(!indicatorsFor) return []
        if(indicatorsFor === 'input') return indicators?.inputIndicators
        if(indicatorsFor === 'graphic') return indicators?.graphicIndicators
        if(indicatorsFor === 'report') return indicators?.reportIndicators
        return []
    }

    useEffect(()=>{
        if(showIndicators || showDashboardIndicators) getIndicators().catch(()=>{})
        if(showBuildingGroups) getBuildingGroups().catch(()=>{})
        if(showBuildings) getBuildings().catch(()=>{})
        if(showRooms && companyId) getRooms().catch(()=>{})
    // eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(!onChange) return
        const filter: FilterType = {}
        if(showBuildings) filter.buildings = buildings?.filter(_ => buildingsSelected.includes(_.id))
        if(showBuildingGroups) filter.buildingGroup = buildingGroups?.find(_ => _.id === buildingGroupSelected)
        if(showIndicators) filter.indicators = _indicators?.filter(_ => indicatorsSelected.includes(_.id))
        if(showStartDate) filter.startDate = startDate
        if(showEndDate) filter.endDate = endDate
        if(showDate) filter.date = date
        if(showDashboardIndicators) filter.dashboardIndicators = _dashboardIndicators?.filter((_, index) => dashboardIndicatorsSelected.includes(index))
        if(showAreas) filter.areas = areas?.filter(_ => areasSelected.includes(_.id))
        if(showRooms) filter.rooms = rooms?.filter(_ => roomsSelected.includes(_.id))

        onChange(filter)
    // eslint-disable-next-line
    }, [buildingGroupSelected, roomsSelected, areasSelected, indicatorsSelected, dashboardIndicatorsSelected, buildingsSelected, startDate, endDate, date, showBuildings, showAreas, showRooms, showBuildingGroups, showIndicators, showStartDate, showEndDate, showDate, showDashboardIndicators])

    useEffect(()=>{
        if(showAreas) getAreas().catch(()=>{})
    // eslint-disable-next-line
    }, [buildingsSelected])
    
    function handleIndicatorsChange(newIndicators: string[] | string){
        let _newIndicators = typeof newIndicators === 'string' ? [newIndicators] : newIndicators
        if(_newIndicators.includes('select_all')) _newIndicators = _indicators?.map(_ => _.id) ?? []
        if(_newIndicators.includes('clear_all')) _newIndicators = []
        setIndicatorsSelected(_newIndicators)
    }

    function handleDashboardIndicatorsChange(newIndicators: string | any[] ){
        let _newIndicators: number[] = []
        if( typeof newIndicators === 'string') {
            if(newIndicators.includes('select_all')) _newIndicators = _dashboardIndicators?.map((_, index) => index) ?? []
            if(newIndicators.includes('clear_all')) _newIndicators = []
        }else{
            _newIndicators = newIndicators
            if(newIndicators.includes('select_all')) _newIndicators = _dashboardIndicators?.map((_, index) => index) ?? []
            if(newIndicators.includes('clear_all')) _newIndicators = []
        }
        setDashboardIndicatorsSelected(_newIndicators)
    }

    function handleBuildingGroupChange(newBuildingGroup: string) {
        setBuildingGroupSelected(newBuildingGroup)
        const group = buildingGroups?.find(_ => _.id === newBuildingGroup)
        const newBuildings = group?.buildings.filter(_ => buildings?.map(_=> _.id).includes(_.id))
        setBuildingsSelected(newBuildings?.map(_ => _.id) ?? [])
    }

    function handleBuildingsChange(newBuildings: string[] | string){
        let _newBuildings = typeof newBuildings === 'string' ? [newBuildings] : newBuildings
        if(_newBuildings.includes('select_all')) _newBuildings = buildings?.map(_ => _.id) ?? []
        if(_newBuildings.includes('clear_all')) _newBuildings = []
        setBuildingsSelected(_newBuildings)
        const group = findGroupOfBuildings(_newBuildings)
        if(group) setBuildingGroupSelected(group.id)
        else setBuildingGroupSelected('')
        setAreasSelected([])
        setRoomsSelected([])
    }

    function handleAreasChange(newAreas: string[] | string){
        let _newAreas = typeof newAreas === 'string' ? [newAreas] : newAreas
        if(_newAreas.includes('select_all')) _newAreas = rooms?.map(_ => _.id) ?? []
        if(_newAreas.includes('clear_all')) _newAreas = []
        setAreasSelected(_newAreas)
        setRoomsSelected([])
    }

    function handleRoomsChange(newRooms: string[] | string){
        let _newRooms = typeof newRooms === 'string' ? [newRooms] : newRooms
        if(_newRooms.includes('select_all')) _newRooms = rooms?.map(_ => _.id) ?? []
        if(_newRooms.includes('clear_all')) _newRooms = []
        setRoomsSelected(_newRooms)
    }

    function findGroupOfBuildings(buildings: string[]) {
        if(!buildingGroups || !buildings || buildings.length <= 0) return
        for( let group of buildingGroups){
            if(arraysEqual(group.buildings.map(_ => _.id), buildings)) return group
        }
        return
    }

    return <View className={style.ContentHeader}>
    {(showBuildingGroups && buildingGroups && buildingGroups.length > 0)  && <View className={style.ContentHeaderSection}>
        <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>Grupo de Prédios</Text>
            {loadingBuildingGroups
            ? <Skeleton color='bg3'/>
            : <Select style={{textAlign: 'left'}} size='small' fullWidth value={buildingGroupSelected} onChange={e => handleBuildingGroupChange(e.target.value)} >
                {buildingGroups?.sort((a,b) => a.name.localeCompare(b.name)).map(_ => <SelectItem key={_.id} value={_.id}>{_.name}</SelectItem>)}
            </Select>
            }
        </View>
    </View>}
    {showBuildings && <View className={style.ContentHeaderSection}>
        <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>{onlyOneBuilding ? `Prédio` :`Prédios`}</Text>
            {buildingsLoading
            ? <Skeleton color='bg3'/>
            : <Select style={{textAlign: 'left'}} size='small' fullWidth multiple={!onlyOneBuilding} value={buildingsSelected} onChange={e => handleBuildingsChange(e.target.value)} renderValue={(selected) => buildings?.filter(_=> selected.includes(_.id)).map(_ => _.name).join(', ')} >
                {!onlyOneBuilding && <SelectItem key={'select_all'} value={'select_all'}>Selecionar todos</SelectItem>}
                {!onlyOneBuilding && <SelectItem key={'clear_all'} value={'clear_all'}>Desmarcar todos</SelectItem>}
                {buildings?.sort((a,b) => a.name.localeCompare(b.name)).map(_ => <SelectItem key={_.id} value={_.id}><Checkbox checked={buildingsSelected.includes(_.id)} />{_.name}</SelectItem>)}
            </Select>
            }
        </View>
    </View>}
    {showAreas && <View className={style.ContentHeaderSection}>
        <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>{onlyOneArea ? `Andar` :`Andares`}</Text>
            {areasLoading
            ? <Skeleton color='bg3'/>
            : <Select style={{textAlign: 'left'}} size='small' fullWidth multiple={!onlyOneArea} value={areasSelected} onChange={e => handleAreasChange(e.target.value)} renderValue={(selected) => areas?.filter(_=> selected.includes(_.id)).map(_ => _.name).join(', ')} >
                {!onlyOneArea && <SelectItem key={'select_all'} value={'select_all'}>Selecionar todos</SelectItem>}
                {!onlyOneArea && <SelectItem key={'clear_all'} value={'clear_all'}>Desmarcar todos</SelectItem>}
                {areas?.sort((a,b) => a.name.localeCompare(b.name)).map(_ => <SelectItem key={_.id} value={_.id}><Checkbox checked={areasSelected.includes(_.id)} />{_.name}</SelectItem>)}
            </Select>
            }
        </View>
    </View>}
    {showRooms && <View className={style.ContentHeaderSection}>
        <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>{onlyOneRoom ? `Sala` :`Salas`}</Text>
            {roomsLoading
            ? <Skeleton color='bg3'/>
            : <Select style={{textAlign: 'left'}} size='small' fullWidth multiple={!onlyOneRoom} value={roomsSelected} onChange={e => handleRoomsChange(e.target.value)} renderValue={(selected) => rooms?.filter(_=> selected.includes(_.id)).map(_ => _.name).join(', ')} >
                {!onlyOneRoom && <SelectItem key={'select_all'} value={'select_all'}>Selecionar todos</SelectItem>}
                {!onlyOneRoom && <SelectItem key={'clear_all'} value={'clear_all'}>Desmarcar todos</SelectItem>}
                {rooms?.sort((a,b) => a.name.localeCompare(b.name)).map(_ => <SelectItem key={_.id} value={_.id}><Checkbox checked={roomsSelected.includes(_.id)} />{_.name}</SelectItem>)}
            </Select>
            }
        </View>
    </View>}
    {showDashboardIndicators && <View className={style.ContentHeaderSection}>
        <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>Indicadores</Text>
            {loadingIndicators
            ? <Skeleton color='bg3'/>
            : <Select style={{textAlign: 'left'}} size='small' fullWidth multiple value={dashboardIndicatorsSelected} onChange={e => handleDashboardIndicatorsChange(e.target.value)} renderValue={(selected) => _dashboardIndicators?.filter((_,index)=> selected.includes(index)).map(_ => _.indicator.name).join(', ')}>
                <SelectItem key={'select_all'} value={'select_all'}>Selecionar todos</SelectItem>
                <SelectItem key={'clear_all'} value={'clear_all'}>Desmarcar todos</SelectItem>
                {_dashboardIndicators?.sort((a,b) => a.indicator.name.localeCompare(b.indicator.name)).map((_, index) => <SelectItem key={index} value={index}><Checkbox checked={dashboardIndicatorsSelected.includes(index)} />{`${_.indicator.name}${_.type === 'line-chart' ? ' (gráfico)' : ''}`}</SelectItem>)}
            </Select>
            }
        </View>
    </View>}
    {showIndicators && <View className={style.ContentHeaderSection}>
        <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>Indicadores</Text>
            {loadingIndicators
            ? <Skeleton color='bg3'/>
            : <Select style={{textAlign: 'left'}} size='small' fullWidth multiple value={indicatorsSelected} onChange={e => handleIndicatorsChange(e.target.value)} renderValue={(selected) => _indicators?.filter((_,index)=> selected.includes(_.id)).map(_ => _.name).join(', ')}>
                <SelectItem key={'select_all'} value={'select_all'}>Selecionar todos</SelectItem>
                <SelectItem key={'clear_all'} value={'clear_all'}>Desmarcar todos</SelectItem>
                {_indicators?.sort((a,b) => a.name.localeCompare(b.name)).map(_ => <SelectItem key={_.id} value={_.id}><Checkbox checked={indicatorsSelected.includes(_.id)} />{_.name}</SelectItem>)}
            </Select>
            }
        </View>
    </View>}
    {(showStartDate || showEndDate )&& <View className={style.ContentHeaderSection}>
        {showStartDate && <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>Inicio:</Text>
            <DatePicker views={dateType && dateType === 'datetime' ? ['year', 'month', 'day', 'hours', 'minutes'] : ['month', 'year']} sx={{width: '100%'}} minDate={new Date(2010,0,1)} maxDate={endDate} value={startDate} onChange={date => setStartDate(date ?? undefined)}/>
        </View>}
        {showEndDate && <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>Fim:</Text>
            <DatePicker views={dateType && dateType === 'datetime' ? ['year', 'month', 'day', 'hours', 'minutes'] : ['month', 'year']} sx={{width: '100%'}} minDate={startDate} maxDate={new Date()} value={endDate} onChange={date => setEndDate(date ?? undefined)}/>
        </View>}
    </View>}
    {(showDate)&& <View className={style.ContentHeaderSection}>
        <View className={style.ContentHeaderSectionItem} >
            <Text type='h4'>Data:</Text>
            <DatePicker views={dateType && dateType === 'datetime' ? ['year', 'month', 'day', 'hours', 'minutes'] : ['month', 'year']} sx={{width: '100%'}} minDate={new Date(2010,0,1)} maxDate={new Date()} value={date} onChange={date => setDate(date ?? undefined)} />
        </View>
    </View>}
    
</View>
}