import { host } from "config/api.config";
import { Room } from "model/room.model";
import { useHTTPClient } from "services/httpClient.service";

const url = `${host}/rooms`

export function useGetRoomsApi(companyId: string, buildingId: string, areaId: string){

    const {data, loading, error, get} = useHTTPClient<Room[]>(`${url}?companyId=${companyId}&buildingId=${buildingId}&areaId=${areaId}`)

    function getApi(){
        return get({})
    }

    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useGetRoomApi(roomId: string){

    const {data, loading, error, get} = useHTTPClient<Room>(`${url}/${roomId}`)

    function getApi(){
        return get({})
    }

    return {
        loading,
        error,
        get: getApi,
        data
    }
}

export function useSaveNewRoomApi(){
    const {loading, error, post } = useHTTPClient<Room>(`${url}`)

    async function save(data: Omit<Room, 'id'>){
        return await post({data: data})
    }

    return {
        loading,
        error,
        save
    }
}

export function useUpdateRoomApi(id: string){
    const {loading, error, put } = useHTTPClient<Partial<Room>>(`${url}/${id}`)

    async function save(data: Partial<Room>){
        return await put({data: data})
    }

    return {
        loading,
        error,
        save
    }
}