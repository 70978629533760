import style from './area.module.css'
import { Page } from "components/page/page.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Switch } from 'components/switch/switch.component';
import { Skeleton } from 'components/skeleton';
import { TextInput } from 'components/textInput/textInput.component';
import { Button } from 'components/button/button.component';
// import { ErrorComponent } from 'components/errorComponent/error.component';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigation } from 'services/navigation.service';
import { Select, SelectItem } from 'components/select/select.component';
import { useGetBuildingsApi} from 'api/building.api';
import { Area } from 'model/area.model';
import { useGetAreaApi, useSaveNewAreaApi, useUpdateAreaApi } from 'api/area.api';

type AreaPageProps = {
    id?: string,
    companyId?: string
}

export function AreaPage({companyId, id}:AreaPageProps){

    const [building, setBuilding] = useState<string>('')
    const [active, setActive] = useState(true)
    const [name, setName] = useState('')
    const { navigate } = useNavigation()
    // const error = false

    const {data: buildings, loading: buildingsLoading, get: getBuildings} = useGetBuildingsApi(companyId ?? '')
    const {data: area, loading, get: getArea} = useGetAreaApi(id ?? '' )
    const {loading: loadingSaveNew, save: saveNewArea} = useSaveNewAreaApi()
    const {loading: laodingUpdate, save: updateArea} = useUpdateAreaApi(id ?? '')

    useEffect(()=>{
        getBuildings().catch(()=>{})
        if(id) getArea().catch(()=>{})
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        if(!area) return
        setName(area?.name)
        setActive(!area.disable)
        setBuilding(area.buildingId)
    // eslint-disable-next-line
    },[area])

    function handleBuildingChange(buildingId: string){
        setBuilding(buildingId)
    }

    function handleActiveChange(e: ChangeEvent<HTMLInputElement>){
        setActive(e.target.checked)
    }

    function handleNameChange(newName: string){
        setName(newName)
    }

    function handleGoBack(){
        navigate(`${companyId? `/${companyId}` : ''}/andares`)
    }

    async function checkFields(_area: Partial<Area>){
        if(!_area.buildingId) throw new Error('Selecione um prédio')
        if(!_area.name) throw new Error('Preencha o nome do andar')
    }

    function saveNew(_area: Omit<Area, "id">){
        saveNewArea(_area)
        .then(()=>{
            alert('Andar criado com sucesso')
            handleGoBack()
        })
        .catch(()=>{ alert(`Erro ao salvar novo prédio`) })
    }

    function update(_area: Partial<Area>){
        updateArea(_area)
        .then(()=>{
            alert('Andar editado com sucesso')
            handleGoBack()
        })
        .catch(()=>{ alert(`Erro ao salvar prédio`) })
    }

    function handleSave(){

        const _area= {
            name: name,
            disable: !active,
            buildingId: building
        }
       
        checkFields(_area)
        .then(()=>{
            if(id) update({id, ..._area})
            else saveNew(_area)
        })
        .catch((error)=>{
            alert(error.message)
            return
        })

    }

    // if(error) return <Page className={style.Page} menu header title='Prédio'> 
    //     <ErrorComponent >
    //         <Button >Atualizar</Button>
    //     </ErrorComponent>
    // </Page>
    
    return <Page menu header title="Andar">
        <View className={style.Content}>
            <View className={style.FormContainer}>
                <View className={style.Form}>
                    
                    <View className={style.FormItemContainer}>
                        <View className={style.DisableContainer}>
                            <Text type='h4'>Ativo</Text>
                            <Switch checked={active} onChange={handleActiveChange}/>
                        </View>
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Prédio</Text>
                        {loading || buildingsLoading
                        ? <Skeleton color='bg3'/>
                        : <Select disabled={loadingSaveNew || laodingUpdate || !!id} size='small' fullWidth value={building} onChange={e => handleBuildingChange(e.target.value)} >
                            {buildings?.map(_ => <SelectItem key={_.id} value={_.id}>{_.name}</SelectItem>)}
                        </Select>
                        }
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Nome</Text>
                        {loading 
                        ? <Skeleton color='bg3'/>
                        : <TextInput disabled={loadingSaveNew || laodingUpdate} size='small' fullWidth value={name} onChange={e => handleNameChange(e.target.value)}/>}
                    </View>                    

                </View>
            </View>
            <View className={style.Buttons}>
                <Button onClick={handleGoBack}>Voltar</Button>
                <Button disabled={loading} loading={loadingSaveNew || laodingUpdate} variant='contained' onClick={handleSave}>Salvar</Button>
            </View>
        </View>
    </Page>
}