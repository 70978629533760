import style from './room.module.css'
import { Page } from "components/page/page.component";
import { View } from "components/view/view.component";
import { Text } from 'components/text/text.component'
import { Switch } from 'components/switch/switch.component';
import { Skeleton } from 'components/skeleton';
import { TextInput } from 'components/textInput/textInput.component';
import { Button } from 'components/button/button.component';
// import { ErrorComponent } from 'components/errorComponent/error.component';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigation } from 'services/navigation.service';
import { Select, SelectItem } from 'components/select/select.component';
import { useGetBuildingsApi } from 'api/building.api';
import { useGetAreasApi } from 'api/area.api';
import { Room } from 'model/room.model';
import { useGetRoomApi, useSaveNewRoomApi, useUpdateRoomApi } from 'api/room.api';

type RoomPageProps = {
    id?: string,
    companyId?: string
}

export function RoomPage({companyId, id}:RoomPageProps){

    const [building, setBuilding] = useState<string>('')
    const [area, setArea] = useState<string>('')
    const [active, setActive] = useState(true)
    const [name, setName] = useState('')
    const { navigate } = useNavigation()
    // const error = false

    const {data: buildings, loading: buildingsLoading, get: getBuildings} = useGetBuildingsApi(companyId ?? '')
    const {data: areas, loading: areasLoading, get: getAreas} = useGetAreasApi(building ?? '')
    const {data: room, loading, get: getRoom} = useGetRoomApi(id ?? '' )
    const {loading: loadingSaveNew, save: saveNewRoom} = useSaveNewRoomApi()
    const {loading: laodingUpdate, save: updateRoom} = useUpdateRoomApi(id ?? '')

    useEffect(()=>{
        getBuildings().catch(()=>{})
        if(id) getRoom().catch(()=>{})
    // eslint-disable-next-line
    },[])

    useEffect(()=>{
        getAreas().catch(()=>{})
    // eslint-disable-next-line
    },[building])

    useEffect(()=>{
        if(!room) return
        setName(room?.name)
        setActive(!room.disable)
        setBuilding(room.buildingId ?? '')
        setArea(room.areaId ?? '')
    // eslint-disable-next-line
    },[room])

    function handleBuildingChange(buildingId: string){
        setBuilding(buildingId)
    }

    function handleAreaChange(areaId: string){
        setArea(areaId)
    }

    function handleActiveChange(e: ChangeEvent<HTMLInputElement>){
        setActive(e.target.checked)
    }

    function handleNameChange(newName: string){
        setName(newName)
    }

    function handleGoBack(){
        navigate(`${companyId? `/${companyId}` : ''}/salas`)
    }

    async function checkFields(_room: Partial<Room>){
        if(!_room.buildingId) throw new Error('Selecione um prédio')
        if(!_room.areaId) throw new Error('Selecione um andar')
        if(!_room.name) throw new Error('Preencha o nome da sala')
    }

    function saveNew(_room: Omit<Room, 'id'>){
        saveNewRoom(_room)
        .then(()=>{
            alert('Sala criada com sucesso')
            handleGoBack()
        })
        .catch(()=>{ alert(`Erro ao salvar novo sala`) })
    }

    function update(_room: Partial<Room>){
        updateRoom(_room)
        .then(()=>{
            alert('Sala editada com sucesso')
            handleGoBack()
        })
        .catch(()=>{ alert(`Erro ao salvar sala`) })
    }

    function handleSave(){

        const _room= {
            companyId: companyId,
            buildingId: building,
            areaId: area,
            name: name,
            disable: !active
        }
       
        checkFields(_room)
        .then(()=>{
            if(id) update({id, ..._room})
            else saveNew(_room)
        })
        .catch((error)=>{
            alert(error.message)
            return
        })

    }

    // if(error) return <Page className={style.Page} menu header title='Prédio'> 
    //     <ErrorComponent >
    //         <Button >Atualizar</Button>
    //     </ErrorComponent>
    // </Page>
    
    return <Page menu header title="Sala">
        <View className={style.Content}>
            <View className={style.FormContainer}>
                <View className={style.Form}>
                    
                    <View className={style.FormItemContainer}>
                        <View className={style.DisableContainer}>
                            <Text type='h4'>Ativo</Text>
                            <Switch checked={active} onChange={handleActiveChange}/>
                        </View>
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Prédio</Text>
                        {loading || buildingsLoading
                        ? <Skeleton color='bg3'/>
                        : <Select disabled={loadingSaveNew || laodingUpdate || !!id} size='small' fullWidth value={building} onChange={e => handleBuildingChange(e.target.value)} >
                            {buildings?.map(_ => <SelectItem key={_.id} value={_.id}>{_.name}</SelectItem>)}
                        </Select>
                        }
                    </View>

                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Andar</Text>
                        {loading || areasLoading
                        ? <Skeleton color='bg3'/>
                        : <Select disabled={loadingSaveNew || laodingUpdate || !!id} size='small' fullWidth value={area} onChange={e => handleAreaChange(e.target.value)} >
                            {areas?.map(_ => <SelectItem key={_.id} value={_.id}>{_.name}</SelectItem>)}
                        </Select>
                        }
                    </View>
                    
                    <View className={style.FormItemContainer}>
                        <Text type='h4'>Nome</Text>
                        {loading 
                        ? <Skeleton color='bg3'/>
                        : <TextInput disabled={loadingSaveNew || laodingUpdate} size='small' fullWidth value={name} onChange={e => handleNameChange(e.target.value)}/>}
                    </View>                    

                </View>
            </View>
            <View className={style.Buttons}>
                <Button onClick={handleGoBack}>Voltar</Button>
                <Button disabled={loading} loading={loadingSaveNew || laodingUpdate} variant='contained' onClick={handleSave}>Salvar</Button>
            </View>
        </View>
    </Page>
}